import Vue from 'vue'
import VueRouter from 'vue-router'
import setTitle from 'vue-set-title';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: require('../views/Home').default
  }
]

const router = new VueRouter({
  routes
})

Vue.use(setTitle, router, 'Cages');

export default router
