import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './utils/i18n'
import 'ly-sass/index.css'
import '/src/assets/scss/global.scss'
import { initScreenUnit } from './utils/common'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
initScreenUnit()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
