import axios from 'axios'
import qs from 'qs'
import { Notify, Toast } from 'vant';

// 请求地址
const url = process.env['VUE_APP_URL'];
const http = axios.create();

// Add a request interceptor
http.interceptors.request.use(function (config) {
  // Do something before request is sent
  Toast.loading({
    message: 'Loading...',
    forbidClick: true,
  });
  config.url = `${location.protocol}//${url}/sites/api/index.php?url=${config.url}`;
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  config.headers['Authorization'] = localStorage.getItem('token');
  if (config.method === 'post') {
    config.data = qs.stringify({
      ...config.data
    })
  }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
});

// Add a response interceptor
http.interceptors.response.use(function (response) {
  // Do something with response data
  Toast.clear();
  if (response.data.succeed === 1) {
    return response.data.data
  } else {
    Notify(response.data.error_desc);

    return Promise.reject(response)
  }
}, function (error) {
  // Do something with response error
  Toast.clear();
  return Promise.reject(error)
});

export default http;
