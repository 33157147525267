<template>
  <div class="home-page full-page">
    <div class="header">
      <img src="../assets/images/header.png">
      <div class="game-image" v-if="game.image">
        <img :src="game.image">
      </div>
    </div>
    <div class="content">
      <div class="game-logo" v-if="game.logo">
        <img :src="game.logo">
      </div>
      <div class="game-name arkitype">
        {{ locale === 'en' ? game.en_name : game.name }}
      </div>
      <div v-if="game.number" class="game-count">
        <span class="arkitype">{{ game.number }}</span>
        <span class="text-orange ml-2">{{ locale === 'en' ? game.unit_en : game.unit }}</span>
        <div v-if="game.prompt" class="game-prompt">
          {{ locale === 'en' ? game.prompt_en : game.prompt }}
        </div>
      </div>
      <div class="game-objective" v-if="objectives && objectives.length">
        <div class="arkitype">{{ $t('objective') }}</div>
        <div class="list-content">
          <div class="list-item" v-for="item in objectives" :key="item">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="game-video arkitype" v-if="game.video">
        <div class="title">{{ $t('video_title') }}</div>
        <div class="text-orange pb-1">{{ $t('video') }}</div>
        <video :src="game.video" controls controlslist="nodownload"></video>
      </div>
      <div class="game-rules" v-if="rules && rules.length">
        <div class="title arkitype">{{ $t('attention') }}</div>
        <div v-if="locale === 'en'" class="arkitype">
          <div class="text-orange">FOR YOUR SAFETY, PLEASE READ INSTRUCTIONS BEFORE YOU PLAY</div>
        </div>
        <div v-else>
          <div class="text-orange">为了您的安全</div>
          <div class="text-orange">请详读游戏规则</div>
        </div>
        <div class="list-content">
          <div class="list-item" v-for="item in rules" :key="item">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GameInstruction } from '@/api/game'
import querystring from 'query-string'

export default {
  data() {
    return {
      game: {},
      objectives: [],
      rules: []
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    }
  },
  mounted() {
    const { game_sn } = querystring.parse(location.search)
    this.queryGame(game_sn)
  },
  methods: {
    queryGame(game_sn) {
      GameInstruction({ game_sn }).then(result => {
        this.game = result
        if (this.locale === 'en') {
          this.objectives = this.transformText(this.game.introduce_en);
          this.rules = this.transformText(this.game.rule_en);
        } else {
          this.objectives = this.transformText(this.game.introduce);
          this.rules = this.transformText(this.game.rule);
        }
      })
    },
    transformText(value) {
      return (value || '').split('\n').map(item => {
        return item.replace('\\','');
      });
    }
  }
}
</script>

<style lang="scss">
.home-page {
  position: relative;
  .header {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 2.8rem;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 5;
    }
    .game-image {
      width: 2.8rem;
      height: 100%;
      position: absolute;
      right: 0;
      z-index: 3;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .content {
    height: 100%;
    padding: 0.2rem;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
    color: #fff;
    .game-logo img {
      width: 0.55rem;
      height: 0.55rem;
    }
    .game-name {
      font-size: 0.4rem;
      padding-top: 1.2rem;
      text-transform: uppercase;
      word-break: break-word;
      width: 70%;
    }
    .text-orange {
      color: #ff8800;
    }
    .game-count {
      padding-top: 0.3rem;
      font-size: 0.3rem;
      .game-prompt {
        font-size: 0.16rem;
        line-height: 0.25rem;
      }
    }
    .game-objective {
      padding-top: 0.3rem;
      font-size: 0.2rem;
    }
    .list-content {
      border: 1px solid #ff8800;
      padding: 0.2rem 0.1rem;
      margin-top: 0.05rem;
      font-size: 0.14rem;
      .list-item {
        padding-bottom: 0.1rem;
        background-image: url('../assets/images/arrow.png');
        background-repeat: no-repeat;
        background-size: 0.15rem;
        padding-left: 0.25rem;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    .game-video {
      padding-top: 0.2rem;
      font-size: 0.2rem;
      line-height: 0.2rem;
      .title {
        font-size: 0.14rem;
        padding-bottom: 0.05rem;
      }
      video {
        width: 100%;
        height: 2rem;
      }
    }
    .game-rules {
      padding-top: 0.2rem;
      font-size: 0.2rem;
      padding-bottom: 1rem;
      .title {
        font-size: 0.14rem;
        padding-bottom: 0.05rem;
      }
      .list-content {
        border: none;
        color: #ddd;
      }
    }
  }
}
</style>
