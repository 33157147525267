import Vue from 'vue'
import VueI18n from 'vue-i18n';

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh-CN',
  messages: {
    'zh-CN': require('../lang/zh').default,
    'en': require('../lang/en').default
  }
});

export default i18n
