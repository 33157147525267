<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import querystring from 'query-string'

export default {
  created() {
    const { lang } = querystring.parse(location.search);
    const locale = this.getLocale(lang);
    this.$i18n.locale = locale;
    localStorage.setItem('locale', locale);
  },
  methods: {
    getLocale (lang) {
      let locale = 'zh-CN';
      if (lang) {
        locale = lang.indexOf('zh') >= 0 ? 'zh-CN' : lang;
        locale = ['en', 'zh-CN'].indexOf(lang) < 0 ? 'zh-CN' : lang;
      } else {
        const lang = navigator.language.indexOf('zh') >= 0 ? 'zh-CN' : 'en';
        locale = localStorage.getItem('locale') || lang || 'zh-CN';
      }
      return locale;
    },
  }
}
</script>

<style lang="scss"></style>
